import { drawer } from "../scripts/main/drawer";
import "../scripts/main/flip-clock";
import "../scripts/main/notifications-manager";
import "../scripts/main/modals";

// TODO: Move as input validation in the product calculator code.
document.addEventListener("DOMContentLoaded", () => {
  initializeDropdowns();
});

window.setLengthLimitForInput = (input, maxLength = 4) => {
  maxLength = parseInt(input.getAttribute('maxlength')) || maxLength;
  if (input.value.length > maxLength) {
    input.value = input.value.slice(0, maxLength);
  }
}

window.cletile = {}
window.cletile.module = { drawer };


function initializeDropdowns() {
  document.addEventListener('click', (e) => {
    const target = e.target;
    if (!target.closest('.js-has-dropdown')) {
      closeAllNav();
    }
  });

  const dropdownHoverElements = document.querySelectorAll(".js-has-dropdown");
  dropdownHoverElements.forEach(element => {
    element.addEventListener('click', (e) => {
      const target = e.target;
      const parent = target.closest('.js-has-dropdown');
      e.stopImmediatePropagation();
      if (parent.classList.contains('js-dropdown-open') && !target.classList.contains('js-has-dropdown')) {
        closeDropdown(parent)
      } else {
        closeSiblings(parent.parentElement);
        openDropdown(parent);
      }
    }, true);
  });
}

function closeAllNav () {
  const dropdowns = document.querySelectorAll('.js-dropdown-open');
  dropdowns.forEach(dropdown => {
    closeDropdown(dropdown);
  });
}

function openDropdown (parent) {
  const childNav = parent.querySelector('div');
  if (!childNav) return;
  parent.classList.add('js-dropdown-open');
  childNav.classList.add('flex');
  childNav.classList.remove('hidden');
}

function closeDropdown (parent) {
  const childNav = parent.querySelector('div');
  if (!childNav) return;
  parent.classList.remove('js-dropdown-open');
  childNav.classList.remove('flex');
  childNav.classList.add('hidden');
}

function closeSiblings (parent) {
  const siblings = parent.querySelectorAll('.js-dropdown-open');
  siblings.forEach(sibling => {
    closeDropdown(sibling);
  });
}