/*globals product,sale_enabled,sale_tag,sale_threshold,sqftInput,overageSelect,addToCartBtn,callMtoBtn,installerCheckbox,overageDeclinedCheckbox */

let endpoint = '/cart/add.js';
let message = 'Product has been added to the cart!';
let error_message = 'There was a problem adding to the cart. Please try again!';

var current_product;

const getContainer = () => {
  const container = document.querySelector(`[data-testid="product-calculator-edit"][data-line-item-index="${window.current_lineitem_index}"]`);
  if (container) {
    current_product = window.products[container.dataset.variantId];
    window.current_product = current_product;
  }
  return container;
}

const getParentContainer = () => {
  const parentContainer = document.querySelector(`[id="cart-item-editing-section"][data-line-item-index="${window.current_lineitem_index}"]`);
  return parentContainer;
};

/**
 * Add to page listeners
 */
document.addEventListener('DOMContentLoaded', async function () {
  refreshCartItemEditListeners();

  const userQuantityInput = document.getElementById('sqft-input-js');
  const overageSelect = document.getElementById('overage-select');
  const addToCartBtn = document.getElementById('addToCart') || false;
  const openCartBtn = document.getElementById('openCart');
  const callMtoBtn = document.getElementById('callMtoBtn') || false;
  const installerCheckbox = document.getElementById('installerVerifiedCheckbox') || false;
  const overageDeclinedCheckbox = document.getElementById('overageDeclinedCheckbox') || false;
  const calculationUnit = document.getElementById('calculator')?.dataset?.calculationUnit
  const product = window.product;
  /**
   * Show initial lead time base on the current sqft qty
   */
  if (product)
    update_lead_time_frontend(product, product.min_qty);

  /**
   * Click listener to reset the current sqft typed value
   */
  userQuantityInput?.addEventListener('click', function () {
    this.value = this.value == 0 ? '' : this.value;
  });

  const typingDelay = 250; // Delay time in milliseconds
  let typingTimer; // Timer identifier

  /**
   * Qty input listener
   */
  userQuantityInput?.addEventListener('input', function () {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      setLengthLimitForInput(userQuantityInput);
      return update();
    }, typingDelay);
  });

  /**
   * Overage select listener
   */
  overageSelect?.addEventListener('change', function () {
    return update();
  });

  /**
   * installer verified checkbox listener
   */
  if (installerCheckbox) {
    installerCheckbox.addEventListener('change', function () {
      update();
      return undefined;
    });
  }

  /**
   * overage checkbox listener
  */
  if (overageDeclinedCheckbox) {
    overageDeclinedCheckbox.addEventListener('change', function () {
      update();
      return undefined;
    });
  }

  /**
   * Add to cart listener
   */
  if (addToCartBtn) {
    addToCartBtn.addEventListener('click', function () {
      update_button(false, addToCartBtn);
      return add_to_cart(parseInt(userQuantityInput.value), parseFloat(overageSelect.value), calculationUnit);
    });
  }

  /**
   * Open cart listener
   */
  if (openCartBtn) {
    openCartBtn.addEventListener('click', () => {
      cart.open();
    });
  }

  if (typeof cart === 'undefined') {
    window.cart = document.querySelector('cle-cart');
  }
});

async function getServerCartJSON() {
  const response = await fetch('/cart.js');
  const cart = await response.json();
  return cart;
}

/**
 * Update calculator both sides frontend and backend
 * @returns {{item_totals: string, total: {coverage: number, boxes: number, pieces: number, amount: number, qty: number}, lead: ({time: number, time_in_weeks: *, message: string}|string), overage: number}}
 */
function update() {
  const userQuantityInput = parseInt(document.getElementById('sqft-input-js').value);
  const overage = parseFloat(document.getElementById('overage-select').value);
  const calculationUnit = document.getElementById('calculator').dataset.calculationUnit
  if (userQuantityInput > 0 && userQuantityInput !== '') {
    let { data } = calculate(product, userQuantityInput, overage, calculationUnit);
    let message = get_order_limit_message(product, overage, calculationUnit);
    document.getElementById('installer-verification')?.classList.remove('invisible');  

    if(product.on_sale && data.total.qty > product.inventory.qty) {
      data.lead.message = message;
      document.getElementById('installer-verification')?.classList.add('invisible');
    }

    update_frontend(product, data);
    return data;
  }
}

/**
 * @param qty
 * @param is_bundle
 * @returns {number}
 */
function calculate_boxes(product_data, qty, is_bundle) {
  if (is_bundle) {
    return Math.ceil(qty * product_data.boxes.per_bundle)
  }
  return Math.ceil(qty / product_data.pieces.per_box);
}

/**
 * @param boxes
 * @returns {number}
 */
function get_total_qty(product_data, boxes) {
  return Math.ceil(boxes * product_data.pieces.per_box)
}

function round(num) {
  if (num - Math.floor(num) >= 0.99) {
      return Math.ceil(num); // Rounds up if the fraction part is 0.99 or more
  } else {
      return Math.floor(num); // Otherwise, rounds down
  }
}


/**
 * @param userQuantityInput
 * @param overage
 * @param calculationUnit
 * @returns {{debug: {qty_needed: number, area_needed: number, typed_sqft}, data: {item_totals: string, total: {coverage: number, boxes: number, pieces: number, amount: number, qty: number}, lead: ({time: number, time_in_weeks: *, message: string}|string), overage: number}}}
 */
function calculate(product_data, userQuantityInput, overage = 0, calculationUnit) {
  let area_needed = null;
  let qty_needed = null;

  switch (calculationUnit) {
    case "pieces":
      // total sqft needed
      area_needed = Math.ceil((userQuantityInput / product_data.pieces.per_unit) * 100) / 100;
      // calculate qty needed
      qty_needed = Math.ceil(userQuantityInput + (userQuantityInput * overage));
      break;

    default:
      // total sqft needed
      area_needed = Math.ceil(userQuantityInput + (userQuantityInput * overage));
      // calculate qty needed
      qty_needed = Math.ceil(area_needed * product_data.pieces.per_unit);
      break;
  }

  // calculate boxes needed
  let total_boxes = calculate_boxes(product_data, qty_needed, product_data.is_bundle);

  let total_qty = get_total_qty(product_data, total_boxes);
  let total_pieces = total_qty;
  if (product_data.is_bundle) {
    // calculate bundle pieces
    total_qty = Math.ceil(qty_needed);
    total_pieces = total_qty * product_data.pieces.per_bundle;
  }

  let total_coverage = (total_qty / product_data.pieces.per_unit) < 1 ? round((total_qty / product_data.pieces.per_unit) * 100) / 100 : round(total_qty / product_data.pieces.per_unit)
  let lead = get_lead_times(product_data, total_qty);
  if (product_data === window.product) {
    const cart_items = cart.items.filter(item => String(item.id) === String(product_data.id));
    const existing_cart_qty = cart_items.reduce((tot, cur) => tot + cur.quantity, 0);
    lead = get_lead_times(product_data, total_qty + existing_cart_qty);
  }
  
  let total_price = (product_data.price * total_qty) / 100;

  let pieces_message = total_pieces === 1 ? `${total_pieces} pc` : `${total_pieces} pcs`
  let coverage_message = `${total_coverage} ${product_data.uom}`
  let boxes_message = total_boxes === 1 ? `${total_boxes} box` : `${total_boxes} boxes`

  let item_totals;
  let bundle_message;
  if (product_data.is_bundle) {
    bundle_message = total_qty === 1 ? `${total_qty} bundle` : `${total_qty} bundles`
    item_totals = `${bundle_message} / ${coverage_message} / ${boxes_message}`
  } else if (calculationUnit != "pieces") {
    item_totals = `${pieces_message} / ${coverage_message} / ${boxes_message}`
  } else {
    item_totals = `${pieces_message} / ${coverage_message}`
  }

  return {
    data: {
      overage,
      lead,
      item_totals,
      total: {
        coverage: total_coverage,
        boxes: total_boxes,
        qty: total_qty,
        pieces: total_pieces,
        amount: total_price
      }
    },
    debug: {
      typed_sqft: userQuantityInput,
      area_needed,
      qty_needed
    }
  };
}

/**
 * Calculate the maximum area based on inventory qty (pieces)
 * @param {Object} product_data - Product details (including inventory and pieces data)
 * @param {Number} overage - The overage percentage to consider (e.g., 0.3 for 30%)
 * @returns {Number} - Maximum valid area in sqft
 */
function get_order_limit_message(productData, overage = 0, calculationUnit = '') {
  if (!productData?.pieces?.per_unit || !productData?.pieces?.per_box) {
    return '';
  }

  const { inventory: { qty: availablePieces }, pieces: { per_unit: piecesPerUnit, per_box: piecesPerBox }, is_bundle: isBundle } = productData;
  const totalQty = isBundle 
      ? availablePieces 
      : Math.floor(availablePieces / piecesPerBox) * piecesPerBox;

  let limit = Math.floor(totalQty / piecesPerUnit / (1 + overage));
  if (calculationUnit === 'pieces') {
      limit = Math.floor(availablePieces / (1 + overage));
  } else {
    calculationUnit = 'sqft';
  }

  return `Only ${limit} ${calculationUnit} in stock for online purchase.<br> To order more than ${limit} ${calculationUnit}, please <a href="/pages/contact-us" class="underline font-semibold" target="_blank" rel="noopener noreferrer">contact our sales team</a>.`;
}


/**
 * Update frontend fields
 * @param data
 */
function update_frontend(product_data, data) {
  /**
   * The false condition indicates that the element could not exist because a liquid condition in product-form
   * @type {HTMLElement}
   */
  const container = getContainer();
  const parentContainer = getParentContainer();
  const product = window.product;
  let updateCondition = product_data === product/* || typeof product === 'undefined'*/;
  const box_elem = updateCondition ? document.getElementById('number_boxes') : container?.querySelector('[data-testid="total_boxes_calc"]');
  const sqft_elem = updateCondition ? document.getElementById('sqft_calc') : container?.querySelector('[data-testid="total_sqft_calc"]');
  const bundle_elem = updateCondition ? document.getElementById('number_bundles') : container?.querySelector('[data-testid="total_bundles_calc"]');
  const min_message_elem = updateCondition ? document.getElementById('order-min-message') : container?.querySelector('[data-testid="order-min-message"]');
  const more_than_elem = document.getElementById('orderingMoreThanAvailable') || false;
  const installer_elem = document.getElementById('installer-verification') || false;
  const pieces_elem = updateCondition ? document.getElementById('total_pieces') : container?.querySelector('[data-testid="total_pieces_calc"]');
  const price_elem = updateCondition ? document.getElementById('final_price') : container?.querySelector('[data-testid="total_price_calc"]');
  const overage_elem = document.getElementById('overage-declined');
  const addToCartBtn = document.getElementById('addToCart') || false;
  const callMtoBtn = document.getElementById('callMtoBtn') || false;
  const updateCartBtn = parentContainer?.querySelector('[data-testid="cartProductEditSave"]');
  const topCoatCheckbox = container?.querySelector('[data-testid="top-coat-add"]');

  let min_sqft_reached = (data.total.coverage >= product_data.min_order);
  let is_sold_out = product_data.allow_backorder === false && product_data.coverage > product_data.inventory.sqft
  // installer_elem ? installer_elem.getElementsByTagName('input').installerVerifiedCheckbox.checked = false : null

  if (more_than_elem) {
    if (is_sold_out) {
      more_than_elem.classList.add('visible');
      if (updateCondition) {
        callMtoBtn ? callMtoBtn.classList.remove('hidden') : null
        addToCartBtn.classList.add('hidden')
      }
    } else {
      more_than_elem.classList.remove('visible');
      if (updateCondition) {
        callMtoBtn ? callMtoBtn.classList.add('hidden') : null
        addToCartBtn?.classList?.remove('hidden')
      }
    }
  }

  if (updateCondition) {
    if (installer_elem) {
      if (min_sqft_reached && !is_sold_out) {
        installer_elem.classList.add('visible');
      } else {
        installer_elem.classList.remove('visible');
      }
    }
    if (overage_elem) {
      if (data.overage === 0.00) {
        overage_elem.classList.add('visible', 'required');
      } else {
        overage_elem.classList.remove('visible', 'required');
      }
    }
    /**
     * Update buttons and lead time
     */
    update_button((terms_accepted(data.overage) && min_sqft_reached && product_data.is_sold_out === false), addToCartBtn);
    update_button((product_data.has_mto_form && min_sqft_reached), callMtoBtn);
  } else {
    update_button((min_sqft_reached && product_data.is_sold_out === false), updateCartBtn);
    if (topCoatCheckbox)
      topCoatCheckbox.checked = product_data.has_top_coat;
  }
  if (min_message_elem) {
    if (min_sqft_reached) {
      min_message_elem.classList.add('hidden');
    } else {
      min_message_elem.classList.remove('hidden');
    }
  }
  update_lead_time_frontend(product_data, data.total.qty, data.lead.message);

  if (sqft_elem) {
    sqft_elem.innerHTML = data.total.coverage;
  }
  if (bundle_elem) {
    bundle_elem.innerHTML = data.total.qty;
  }
  if (box_elem) {
    box_elem.innerHTML = data.total.boxes
  }
  if (pieces_elem) {
    pieces_elem.innerHTML = data.total.pieces
    price_elem.innerHTML = data.total.amount.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  }
}

/**
 * @param overage
 * @returns boolean
 */
function terms_accepted(overage = 0) {
  let tnc = document.querySelectorAll('#installerVerifiedCheckbox');
  if (overage === 0) {
    tnc = document.querySelectorAll('#overageDeclinedCheckbox, #installerVerifiedCheckbox');
  }
  let acknowledge = 0;
  let checkboxes_count = tnc.length;
  tnc.forEach(function (checkbox) {
    if (checkbox.offsetParent === null) {
      checkboxes_count--;
    }
    if (checkbox.checked) {
      acknowledge++;
    }
  });
  return Array.from(tnc).every(checkbox => checkbox.checked)
}

/**
 * Update lead time DOM element
 * @param pieces
 * @param message
 * @returns {boolean}
 */
function update_lead_time_frontend(product_data, pieces, message = '') {
  const container = getContainer();
  const lead_elem = product_data === window.product ? document.getElementById('lead-time') : container?.querySelector('[data-testid="lead-time"]');

  if (lead_elem) {
    let calculation = get_lead_times(product_data, pieces);
    let lead_message = message === '' ? calculation.message : message;
    if (lead_message === 'out of stock' || lead_message.includes('in stock') === false) {
      lead_elem.classList.add('cl-error');
    } else {
      lead_elem.classList.remove('cl-error');
    }
    lead_elem.innerHTML = lead_message;
    return true;
  }
  return false;
}

function clearCacheCartItemData(cartItemId) {
  let data = {};
  try {
    data = JSON.parse(localStorage.getItem('cle-cart-item-data-cache')) || {};
    delete data[cartItemId];
  } catch (err) {
    data = {};
  }
  localStorage.setItem('cle-cart-item-data-cache', JSON.stringify(data));
}

function cacheCartItemData(cartItemId, value) {
  let data;
  try {
    data = JSON.parse(localStorage.getItem('cle-cart-item-data-cache')) || {};
  } catch (err) {
    data = {};
  }
  if (value) {
    data[cartItemId] = value;
    localStorage.setItem('cle-cart-item-data-cache', JSON.stringify(data));
  }
  return data[cartItemId];
}

function refreshPDPCalculator() {
  const product = window.product;
  const calculatorSectionDom = document.getElementById("calculator-content");
  if (calculatorSectionDom) {
    const calculatorFormDom = calculatorSectionDom.querySelector("#calculator-form");
    const quantityInput = calculatorSectionDom.querySelector("#sqft-input-js");
    const overageSelect = calculatorSectionDom.querySelector("#overage-select");
    const addToCartBtn = calculatorSectionDom.querySelector("#addToCart");
    const openCartBtn = calculatorSectionDom.querySelector("#openCart");
    const cartItem = cart.items.find(item => String(item.id) === String(product?.id));
    if (cartItem) {
      quantityInput.disabled = true;
      overageSelect.disabled = true;
      addToCartBtn.style.pointerEvents = 'none';
      openCartBtn.classList.remove('hidden');
      calculatorFormDom.style.filter = 'blur(5px)';
    } else {
      quantityInput.disabled = false;
      overageSelect.disabled = false;
      addToCartBtn.style.pointerEvents = '';
      openCartBtn.classList.add('hidden');
      calculatorFormDom.style.filter = '';
    }
  }
}

window.cacheCartItemData = cacheCartItemData;
window.clearCacheCartItemData = clearCacheCartItemData;
window.refreshPDPCalculator = refreshPDPCalculator;

function refreshCartItemEditListeners() {
  const parentContainer = getParentContainer();
  if (!parentContainer)
    return;
  const userQuantityInputElement = parentContainer.querySelector('[data-testid="sqft-input-js"]');
  const overageSelectElement = parentContainer.querySelector('[data-testid="overage-select"]');
  const updateCartElement = parentContainer?.querySelector('[data-testid="cartProductEditSave"]');
  
    /**
   * Click listener to reset the current sqft typed value
   */
    userQuantityInputElement?.addEventListener('click', function () {
      this.value = this.value == 0 ? '' : this.value;
    });
  
    const typingDelay = 250; // Delay time in milliseconds
    let typingTimer; // Timer identifier
  
    /**
     * Qty input listener
     */
    userQuantityInputElement?.addEventListener('input', function () {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        setLengthLimitForInput(userQuantityInputElement);
        updateCalculation();
      }, typingDelay);
    });

  /**
   * Overage select listener
   */
  overageSelectElement.addEventListener('change', updateCalculation);

  updateCartElement?.addEventListener('click', () => {
    const { userQuantityInput, overage, calculationUnit } = getQuantityFromInput();
    modify_cart_item_quantity(userQuantityInput, overage, calculationUnit);
    window.current_product['coverage'] = userQuantityInput;
    cacheCartItemData(current_product.id, { sqft_needed: userQuantityInputElement.value, overage: overageSelectElement.value });
  });

  userQuantityInputElement.value = cacheCartItemData(current_product.id)?.sqft_needed || '';
  overageSelectElement.value = cacheCartItemData(current_product.id)?.overage || '0';
  updateCalculation();
  refreshPDPCalculator();
};

function getQuantityFromInput() {
  const container = getContainer();
  const userQuantityInput = parseInt(container.querySelector('[data-testid="sqft-input-js"]').value);
  const overage = parseFloat(container.querySelector('[data-testid="overage-select"]').value);
  const calculationUnit = container.dataset.calculationUnit;
  return {
    userQuantityInput,
    overage,
    calculationUnit
  };
};

/**
 * @param enable
 * @param element {string | HTMLButtonElement}
 */
function update_button(enable, element) {
  if (element) {
    typeof (element) === 'string' ? element = document.getElementById(element) : element
    if (enable) {
      element.classList.remove('button-disabled', 'disabled');
      element.removeAttribute('disabled');
    } else {
      element.classList.add('button-disabled', 'disabled');
      element.setAttribute('disabled', 'disabled');
    }
  }
  return undefined;
}

/**
 * Update calculator both sides frontend and backend
 * @param userQuantityInput
 * @param overage
 * @param calculationUnit
 * @returns {{item_totals: string, total: {coverage: number, boxes: number, pieces: number, amount: number, qty: number}, lead: ({time: number, time_in_weeks: *, message: string}|string), overage: number}}
 */
function updateCalculation() {
  const { userQuantityInput, overage, calculationUnit } = getQuantityFromInput();
  if (userQuantityInput > 0 && userQuantityInput !== '') {
    let { data } = calculate(current_product, userQuantityInput, overage, calculationUnit);
    let message = get_order_limit_message(current_product, overage, calculationUnit);

    if(current_product.on_sale && data.total.qty > current_product.inventory.qty) {    
      data.lead.message = message;
    }

    update_frontend(current_product, data);
    return data;
  }
}
window.updateCalculation = updateCalculation;

/**
 * Event that is triggered when product item is loaded for editing
 */
function onLoadCartProductItemForEditing() {
  const calculatorScripts = document.querySelectorAll('[data-testid="script-calculator-edit"]')
  calculatorScripts.forEach((script) => {
    eval(script.textContent);
  });
  updateCalculation();
  refreshCartItemEditListeners();
}
window.onLoadCartProductItemForEditing = onLoadCartProductItemForEditing;

/**
 * @param {Number} total_pieces
 * @returns {{time: number, time_in_weeks: *, message: string}|string}
 */
function get_lead_times(product_data, total_pieces) {
  let lead_time = product_data.lead_time.out,
    lead_time_message;

  if (product_data.tier === 'E') {
    lead_time = product_data.lead_time.in;
    lead_time_message = 'estimated availability for shipping from warehouse in ';
  } else if (product_data.inventory.qty >= total_pieces) {
    lead_time = product_data.lead_time.in;
    lead_time_message = 'in stock - ships from warehouse within ';
  } else if (product_data.tier !== 'M') {
    lead_time_message = 'in transit - ships from warehouse within ';
  } else {
    lead_time_message = 'made to order - ships from warehouse within ';
  }

  let lead_time_in_weeks = lead_time / 7;

  if (lead_time === 7) {
    lead_time_message += '1 week';
  } else if (lead_time % 7 === 0) {
    let lead_time_in_weeks_range = lead_time_in_weeks + 2; // adds two weeks to the initially expected Port ETA
    lead_time_message += lead_time_in_weeks + ' - ' + lead_time_in_weeks_range + ' weeks';
  } else {
    lead_time_message += lead_time + ' business days';
  }

  let lead_time_data = {
    time: lead_time,
    time_in_weeks: lead_time_in_weeks,
    message: lead_time_message
  };

  if (product_data.is_sold_out || (product_data.inventory.qty < total_pieces && !product_data.allow_backorder)) {
    lead_time_data.message = product_data.on_sale === true ? 'sale stock sold out' : 'out of stock';
  }

  return lead_time_data;
}

/**
 * @param {Number} userQuantityInput
 * @param {Number} overage
 */
function add_to_cart(userQuantityInput, overage = 0, calculationUnit) {
  const sealant_elem = document.getElementById('top-coat-add') || false;
  const product = window.product;
  let product_id = product.id
  let cart_item = $('[data-id^="' + product.id + '"]');
  let cart_sqft = 0;
  let cart_qty = 0;
  if (cart_item.length) {
    cart_sqft = parseFloat(cart_item.data('coverage')) || 0;
    cart_qty = parseInt(cart_item.data('qty')) || 0;
  }

  let data;
  let existingOverage = 0;
  const existingCartItem = window.cacheCartItemData(product.id);
  if (existingCartItem) {
    existingOverage = existingCartItem.overage || 0;
  }
  overage = Math.max(Number(overage), Number(existingOverage));

  if (cart_sqft > 0 && calculationUnit != 'pieces') {
    window.cacheCartItemData(product.id, { sqft_needed: userQuantityInput + cart_sqft, overage: overage });
    data = calculate(product, userQuantityInput + cart_sqft, overage, calculationUnit).data;
  } else {
    window.cacheCartItemData(product.id, { sqft_needed: userQuantityInput + cart_qty, overage: overage });
    data = calculate(product, userQuantityInput + cart_qty, overage, calculationUnit).data;
  }

  let sealant_item = {};
  if (sealant_elem && sealant_elem.checked && product.sealant.id > 0) {
    sealant_item = {
      id: product.sealant.id,
      quantity: Math.ceil(data.total.coverage / product.sealant.coverage),
      properties: {
        '__Add-on': 'true'
      }
    };
  }

  let payload = {};
  let item = {
    id: product_id,
    quantity: data.total.qty,
    properties: {
      '__Lead Message': data.lead.message,
      '__Item Totals': data.item_totals,
      '__Overage': (data.overage * 100) + '%',
      '__Coverage': data.total.coverage
    }
  };

  if (product.on_sale) {
    item.properties.__sale = {
      'on_sale': product.on_sale,
      'tag': sale_tag,
      'minimum': sale_threshold
    };
  }

  if (Object.keys(sealant_item).length !== 0 && product.sealant.id != 0) {
    item.properties.__addOn = {
      id: product.sealant.id,
      quantity: Math.ceil(data.total.coverage / product.sealant.coverage),
      price: product.sealant.price
    };
  }

  if (!product.is_bundle) {
    let dimensions = {
      __Height: product.dimensions.height,
      __Length: product.dimensions.length,
      __Thickness: product.dimensions.thickness
    }
    item.properties = { ...item.properties, ...dimensions }
  }

  if (cart_sqft > 0) {
    const cartItem = cart.items.find(item => String(item.id) === String(product_id));
    // TODO: HIDE INTERNAL PROPERTY, check remaining codebase for usage
    item.properties.__product_template = cartItem?.properties.__product_template;
    payload = item;
    endpoint = '/cart/change.js';
  } else {
    endpoint = '/cart/add.js';
    item.properties = { ...item.properties, __product_template: window.product_template };
    payload.items = [item]
    if (Object.keys(sealant_item).length !== 0 && product.sealant.id) {
      payload.items.push(sealant_item)
    }
  }

  const Cart = document.querySelector('cle-sidebar-cart') || document.querySelector('cle-cart');

  fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(async (response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    await Cart.refresh();
    await Cart.updateCount();
    Cart.open();
    update();
    refreshPDPCalculator();
  })
  .catch(error => {
    console.log('error: ', error);
    window.notificationsManager.open({
      type: window.notificationsManager.types.NOTIFICATION_ERROR,
      body: error_message
    });
  })
  .finally(() => {
    update_button(true, 'addToCart');
  });
}

/**
 * @param {Number} userQuantityInput
 * @param {Number} overage
 */
function modify_cart_item_quantity(userQuantityInput, overage = 0, calculationUnit) {
  const container = getContainer();
  const sealant_elem = container.querySelector('[data-testid="top-coat-add"]') || false;
  let product_id = current_product.id;

  let { data } = calculate(current_product, userQuantityInput, overage, calculationUnit);

  let sealant_item = {};
  if (sealant_elem && sealant_elem.checked && current_product.sealant.id > 0) {
    sealant_item = {
      id: current_product.sealant.id,
      quantity: Math.ceil(data.total.coverage / current_product.sealant.coverage),
      properties: {
        '__Add-on': 'true'
      }
    };
  }

  let payload = {};
  const cartItem = cart.items.find(item => String(item.id) === String(product_id));
  let item = {
    id: product_id,
    quantity: data.total.qty,
    properties: {
      '__Lead Message': data.lead.message,
      '__Item Totals': data.item_totals,
      '__Overage': (data.overage * 100) + '%',
      '__Coverage': data.total.coverage,
      '__product_template': cartItem?.properties.__product_template
    }
  };

  if (current_product.on_sale) {
    item.properties.__sale = {
      'on_sale': current_product.on_sale,
      'tag': sale_tag,
      'minimum': sale_threshold
    };
  }
  if (Object.keys(sealant_item).length !== 0 && current_product.sealant.id != 0) {
    item.properties.__addOn = {
      id: current_product.sealant.id,
      quantity: Math.ceil(data.total.coverage / current_product.sealant.coverage),
      price: current_product.sealant.price
    };
  }

  if (!current_product.is_bundle) {
    let dimensions = {
      __Height: current_product.dimensions.height,
      __Length: current_product.dimensions.length,
      __Thickness: current_product.dimensions.thickness
    }
    item.properties = { ...item.properties, ...dimensions }
  }

  const Cart = document.querySelector('cle-sidebar-cart') || document.querySelector('cle-cart');

  fetch('/cart/change.js', {
    method: 'POST',
    body: JSON.stringify(item),
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(async response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      await Cart.refresh();
      await Cart.updateCount();
      Cart.open();
    })
    .catch(error => {
      console.log('error: ', error);
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_ERROR,
        body: error_message
      });
    });
}