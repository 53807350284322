import algoliasearch from 'algoliasearch';
import instantsearch from 'instantsearch.js';

// Import connectors directly
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';
import { connectHits } from 'instantsearch.js/es/connectors';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { index, configure } from 'instantsearch.js/es/widgets';
import aa from "search-insights";

let lastRenderArgs;
let productQuery;
let lastRefinement;

/** Initialize Algolia Insights **/
aa('init', {
  appId: 'CBR292HQN9',
  apiKey: '2ae1998b7ee37c0442ce368a6c5f6041',
  useCookie: true,
});

const insightsMiddleware = createInsightsMiddleware({ insightsClient: aa });

/** Utility for Conversion Tracking **/
const localStorageKey = 'algolia_analytics_clicked_objects';
const saveForConversionTracking = (data) => {
  try {
    const previousClickItems = JSON.parse(localStorage.getItem(localStorageKey) || '[]');
    const conversionData = { ...data, eventName: 'Purchase' };
    delete conversionData.positions;
    delete conversionData.eventType;
    previousClickItems.push(conversionData);
    localStorage.setItem(localStorageKey, JSON.stringify(previousClickItems));
  } catch (error) {
    console.warn('Error saving conversion data:', error);
  }
};

// Create a render functions
const renderRefinementList = (renderOptions, isFirstRender) => {

  const {
    items,
    refine,

    widgetParams,
  } = renderOptions;

  const refinementContainer = document.querySelector(widgetParams.container);
  if (productQuery.length >= 3) {
    refinementContainer.innerHTML = items
      .map(
        item => `
          <button
          data-v-6056a87e=""
          data-v-16bc8626=""
          data-value="${item.value}"
          aria-label="Select ${item.label}"
          class="bg-cl-primary brdr-1 brdr-cl-primary brdr-square h5 cl-tertiary generic-selector filter ${item.isRefined ? 'active' : ''}"
          context="search"
          >
            ${item.label}
          </button>
        `
      )
      .join('');

    [...refinementContainer.querySelectorAll('button')].forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault();
        refine(event.currentTarget.dataset.value);
        lastRefinement = event.currentTarget.dataset.value;
        console.log(event.currentTarget.dataset.value);
      });
    });
  }
};
const renderSearchBox = (renderOptions, isFirstRender) => {
  const { query, refine, clear, isSearchStalled, widgetParams } = renderOptions;

  const renderSearchBoxContainer = document.querySelector(widgetParams.container);
  productQuery = query;

  if (isFirstRender) {
    const input = document.createElement('input');

    const makeSearch = debounce((event) => refine(event.target.value), 400)

    input.addEventListener('input', makeSearch);

    renderSearchBoxContainer.appendChild(input);
  }

  const searchInput = renderSearchBoxContainer.querySelector('input');
  searchInput.setAttribute('data-v-95ff870c', '');
  searchInput.setAttribute('id', 'search')
  searchInput.setAttribute('placeholder', widgetParams.placeholder);
  searchInput.setAttribute('type', 'text');
  searchInput.classList.add("search-panel-input", "h3");

  document.getElementById('query-highlight').innerHTML = query;

  const searchResultsContainer = document.getElementById('search_results_container');
  const searchCloseButton = document.getElementById('search-close');

  searchResultsContainer.style.display = 'none';
  searchCloseButton.style.display = 'none';
  if (query.length >= 3) {
    searchResultsContainer.style.display = 'block';
    searchCloseButton.style.display = 'inline-block';
  }
};
const renderClearRefinements = (renderOptions, isFirstRender) => {
  const { canRefine, refine, widgetParams } = renderOptions;

  const clearRefinementsContainer = document.querySelector(widgetParams.container);

  if (isFirstRender) {
    const span = document.createElement('span');
    span.textContent = 'clear';
    span.setAttribute('data-v-16bc8626', '');
    span.setAttribute('disabled', 'true');
    if (lastRefinement)
      span.setAttribute('disabled', 'false');
      span.addEventListener('click', () => {
        refine();
      });

    clearRefinementsContainer.appendChild(span);
  }

  clearRefinementsContainer.querySelector('span').disabled = !canRefine;
};
const renderInfiniteHits = (renderOptions, isFirstRender) => {
  const {
    hits,
    widgetParams,
    showMore,
    bindEvent,
    sendEvent
  } = renderOptions;
  
  const { container } = widgetParams;

  lastRenderArgs = renderOptions;

  const infiniteHitsContainer = document.querySelector(container);
  const scrollContainer = document.getElementById('search-panel');

  if (isFirstRender) {
    infiniteHitsContainer.addEventListener('click', function (event) {
      const clickedEl = event.target
      if (!clickedEl.dataset.insightsEvent) { return }
      
      const sku = clickedEl.dataset.sku
      const insightsData = JSON.parse(decodeURIComponent(atob(clickedEl.dataset.insightsEvent)))
      saveForConversionTracking({...insightsData, sku})
      sendEvent(insightsData)

      const targetLink = clickedEl.querySelector('a')
      window.location.assign(targetLink.href)
    })

    return;
  }

  let lastCalled = Date.now();
  scrollContainer.addEventListener('scroll', function () {
    if (scrollContainer.offsetHeight + scrollContainer.scrollTop >= scrollContainer.scrollHeight && !lastRenderArgs.isLastPage) {
      if (Date.now() - lastCalled > 1000) {
        showMore();
        lastCalled = Date.now();
      }
    }
  });

  if (productQuery.length >= 3) {

    infiniteHitsContainer.innerHTML = `
      ${hits
        .map(
          item =>
            `
            <div
            class="col-12 product flex flex-column justify-between mb4 pointer"
            ${bindEvent('click', item, 'Product clicked')}
            data-sku="${item.sku}"
            >
            <a
              href="/products/${item.handle}"
              class="flex flex-column justify-between no-underline product-link pointer-events-none"
              data-testid="productLink">
              <div
                data-v-55ee86c9=""
                class="product-image relative product-image--loaded">
                <img
                  data-v-55ee86c9=""
                  alt="${item.title.split(' ~')[0]}"
                  height="auto"
                  width="100%"
                  data-testid="productImage"
                  class="product-image__content og"
                  data-src="${item.image}?height=200&width=200"
                  src="${item.image}?height=200&width=200"
                  lazy="loaded"
                />
              </div>
              <div data-v-55ee86c9="" class="col-12 flex flex-wrap my1">
                <div data-v-55ee86c9="" class="flex col-12 justify-between">
                  <p data-v-55ee86c9="" class="weight-600">
                  ${item.title.split(' ~')[0]}
                  </p>
                  <div data-v-55ee86c9="" class="flex flex-wrap weight-400">
                    <div data-v-55ee86c9="" class="col-12">
                      <p data-v-55ee86c9="" class="text-right price-box">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
            `
        )
        .join('')}
    `;
  } else if (productQuery.length <= 2 && productQuery.length > 0) {
    infiniteHitsContainer.innerHTML =
      `
    <div data-v-95ff870c="" class="no-results relative center-xs h4 col-md-12">
      Searched term should consist of at least 3 characters.
    </div>
    `
  }
};
const renderBlogHits = (renderOptions, isFirstRender) => {
  const { hits, widgetParams, results } = renderOptions;
  const renderHitsContainer = document.querySelector(widgetParams.container);
  if (hits.length > 0) {
    renderHitsContainer.innerHTML = `
      <div data-v-95ff870c="" class="font-bold articles_header">
        blog articles
      </div>
      <div  data-v-95ff870c="" class="post-listing">
      ${hits
          .map(
            item =>
              `
            <p data-v-95ff870c="" class="article_title weight-400">
              <a
                data-v-95ff870c=""
                href="/blogs/${item.blog.handle}/${item.handle}"
                class="">
                <span data-v-95ff870c="">${item.title}</span>
              </a>
            </p>
            `
          )
          .join('')}
      </div>
      ${
      results.nbPages > 0
      ? '<a href="/search?query=' + results.query + '" class="block text-[14px] font-semibold underline mt-2">more articles</a>'
      : ''
      }
    `;
  } else {
    renderHitsContainer.innerHTML = `
      <div data-v-95ff870c="" class="font-bold articles_header">
      blog articles
      </div>
      <div  data-v-95ff870c="" class="post-listing">
      <p data-v-95ff870c="" class="article_title weight-400">
      no articles found
      </p>
      </div>`;
      }
};

const renderHelpHits = (renderOptions, isFirstRender) => {
  const { hits, widgetParams } = renderOptions;
  const renderHitsContainer = document.querySelector(widgetParams.container);
  if (hits.length > 0) {
    renderHitsContainer.innerHTML = `
      <div data-v-95ff870c="" class="font-bold articles_header">
        help articles
      </div>
      <div class="post-listing">
      ${hits
          .map(
            item =>
              `
            <p data-v-95ff870c="" class="article_title weight-400">
              <a
                data-v-95ff870c=""
                href="${item.url}"
                target='_blank'>
                <span data-v-95ff870c="">${item.title}</span>
              </a>
            </p>
            `
          )
          .join('')}
      </div>
    `;
  } else {
    renderHitsContainer.innerHTML = `
    <div data-v-95ff870c="" class="font-bold articles_header">
    help articles
    </div>
    <div  data-v-95ff870c="" class="post-listing">
    <p data-v-95ff870c="" class="article_title weight-400">
    no articles found
    </p>
    </div>`;
  }
};

const renderOuterCleHits = (renderOptions, isFirstRender) => {
  const { results , widgetParams } = renderOptions;
  const renderHitsContainer = document.querySelector(widgetParams.container);
  if (results?.nbHits > 0 && productQuery.length >= 3) {
    renderHitsContainer.innerHTML = `
      <a 
        href="https://www.outercle.com/?query=${results.query}"
        target="_blank"
      >
        Search ${results.nbHits} ${results.nbHits === 1 ? "result" : "results" } in <img src="http://outercle.myshopify.com/cdn/shop/files/logo.svg?v=1707756754&amp;width=200" alt="OUTERclé logo" class="inline-block h-10 mb-[4px]" >
      </a>
    `
  } else {
    renderHitsContainer.innerHTML = ""
  }
};

// create custom widgets
const customSearchBox = connectSearchBox(
  renderSearchBox
);

const customInfiniteHits = connectInfiniteHits(
  renderInfiniteHits
);
const blogHits = connectHits(
  renderBlogHits
);

const helpHits = connectHits(
  renderHelpHits
);

const outerCleHits = connectHits(
  renderOuterCleHits
);

const algoliaClient = algoliasearch(
  "CBR292HQN9",
  "2ae1998b7ee37c0442ce368a6c5f6041"
);

let userToken;

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const search = instantsearch({
  indexName: "shopify_live_products",
  searchClient,
});

search.use(insightsMiddleware)

search.addWidgets([
  configure({
    hitsPerPage: 15,
    clickAnalytics: true,
    userToken
  }),
  customSearchBox({
    container: "#searchbox",
    placeholder: "type here",
    autofocus: true,
    searchAsYouType: true,
    showReset: false,
    showSubmit: false,
    showLoadingIndicator: true,
  }),
  customInfiniteHits({
    container: "#searchListing", // update this selector to match your search page
  }),
  index({ indexName: "shopify_live_articles" }).addWidgets([
    configure({
      hitsPerPage: 3,
    }),
    blogHits({
      container: "#cle_blog",
    }),
  ]),
  index({ indexName: "Knowledge Base" }).addWidgets([
    configure({
      hitsPerPage: 3,
    }),
    helpHits({
      container: "#cle_help",
    }),
  ]),
  index({indexName: "outercleproducts"}).addWidgets([
    configure({
      hitsPerPage: 3,
    }),
    outerCleHits({
      container: "#outercleproducts",
    }),
  ]),
]);

search.start();
